/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import Image from '../components/Image';

const LinkToBook = ({ hash, children }) => (
  <Link to={`/publications#${hash}`}>{children}</Link>
);

LinkToBook.propTypes = {
  hash: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

const Givental = () => (
  <div className="landing">
    <Helmet title="КЭСПА – Инна Гивенталь" />
    <div className="givental-wrapper page-wrapper narrow-container">
      <h1>Инна Гивенталь</h1>
      <p>
        Инна Гивенталь – филолог, создатель оригинальной методики обучения
        английскому языку КЭСПА, автор пяти учебников.
      </p>

      <Image src="givental.jpg" alt="Инна Гивенталь" className="givental" />

      <h2>О методике</h2>

      <p>
        В основе разработанной методики – отказ от традиционного подхода к
        обучению иностранным языкам, при котором в первую очередь стараются
        развить умение понимать Чужое.
      </p>

      <p>
        Вместо этого И. Гивенталь предложила прежде всего развивать умение
        выражать своё средствами Чужого, то есть приобретать знания,
        отталкиваясь не от незнакомого Чужого, а от привычного Родного.
      </p>

      <p>
        Чтобы «объять необъятное» – процесс говорения как таковой – придумала
        новую единицу структурирования речи и назвала её{' '}
        <span className="emphasized">«прагмалингвистическая формула».</span>
      </p>

      <p>
        Разработала особую систему артикуляционно-грамматических упражнений,
        которые позволяют освоить грамматику и общеупотребительную лексику
        английского языка так, чтоб «все от зубов отскакивало».
      </p>

      <p>
        Эти теоретические разработки нашли своё практическое воплощение в первых
        двух учебниках{' '}
        <LinkToBook hash="kespa">«Как это сказать по‑английски»</LinkToBook> и{' '}
        <LinkToBook hash="kuivpa">
          «Как удивиться и возмутиться по-английски».
        </LinkToBook>
      </p>

      <h2>О персональном подходе</h2>

      <p>
        И. Гивенталь считает, что научить выражать себя по-английски можно всех,
        включая и тех, кто считает себя с рождения не способным к языкам. Надо
        лишь учитывать личностные особенности каждого конкретного ученика. Чтобы
        процесс освоения Чужого языка был эффективным и приятным, Гивенталь
        ввела ещё одно новое понятие –{' '}
        <span className="emphasized">прагмалингвистический тип человека</span>.
      </p>

      <p>
        Книги{' '}
        <LinkToBook hash="s_nulya">
          «Английский с нуля для детей и взрослых»
        </LinkToBook>{' '}
        и <LinkToBook hash="rechejer">«Речевой тренажёр»</LinkToBook> на
        практике воплотили эту идею – идею индивидуального подхода к обучению
        иностранному языку.
      </p>

      <h2>О проблемах изучения английского</h2>

      <p>
        КЭСПА-метод был задуман четверть века назад, как своеобразная прививка
        против «немого» говорения, чтоб помочь людям, которые много лет зубрили
        грамматику, преодолеть психологический языковой барьер и заговорить,
        наконец, по-английски. Однако за последние 15 лет жизнь в постсоветском
        пространстве очень изменилась; подросло новое поколение, которое
        совершенно не боится говорить по-английски. Они говорят бегло, но, к
        сожалению, неграмотно.{' '}
      </p>

      <p>
        И. Гивенталь решила использовать свой метод и для борьбы с новой
        коммуникативной инфекцией – неграмотным говорением, и в 2016 году
        появился ещё один учебник –{' '}
        <LinkToBook hash="grammajer">«Грамматический тренажёр».</LinkToBook>
      </p>

      <Image
        src="books/kespa_books_5.jpg"
        alt="Учебники КЭСПА"
        className="kespa-books"
      />

      <h2>Online-школа</h2>

      <p>
        И. Гивенталь воспитала более двадцати молодых преподавателей и вместе с
        ними создала школу английского языка КЭСПА.
      </p>

      <p>
        Сейчас, помимо работы над новыми книгами и методическими материалами,
        концентрирует свои усилия на разработке гибридной методики
        online-обучения и на подготовке новых преподавателей для такого обучения
      </p>

      <p>
        Если вы хотите научиться преподавать английский по КЭСПА-методике, то
        вам{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeKvJcPRLeVoaNmFKhcnwVEeDWt4ruOUbbq3L8twAYsXWNh4w/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          сюда
        </a>
        , а если вы хотели бы связаться с Инной напрямую, ее можно{' '}
        <a
          href="https://www.facebook.com/inna.givental"
          rel="noopener noreferrer"
          target="_blank"
        >
          найти в Facebook
        </a>
        .
      </p>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(Givental));
